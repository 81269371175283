import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import "./Navbar.css"
export default function Navbar() {
    return(
            <AppBar position="static" style = {{backgroundColor:"rgb(255,255,255)"}}>
            <Toolbar style = {{color:"rgba(0, 0, 0, 0.54)"}}>
            <Typography variant="h6" color="inherit" component="div" sx={{flexGrow: 1}}>
            <img class="logo ringgo-logo" src="/images/ringo.png" alt="RingGo" height="65" width="180" style = {{maxWidth:"8rem", height:"auto"}}>
            </img>
            </Typography>
            <IconButton style = {{color:"#872878"}}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu">
                    <MenuIcon />
            </IconButton>
            </Toolbar>
            </AppBar>
    )
}